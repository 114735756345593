* {
   scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
   width: 10px;
}

*::-webkit-scrollbar-track {
   background: #f0ebeb;
}

*::-webkit-scrollbar-thumb {
   background-color: rgb(165, 159, 159);
}

p {
   margin: 0;
}